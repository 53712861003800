import { Icon } from '@eventbrite/eds-icon';
import {
    ChevronDownChunky,
    ChevronUpChunky,
} from '@eventbrite/eds-iconography';
import React, { useRef, useState } from 'react';
import {
    Actions,
    Components,
    Features,
    getHeapDataAttribute,
    Teams,
} from '../../../utils/heap-data';
import { Dropdown } from '../Dropdown';
import { LinkCreatorPropsInterface } from '../utils/interfaces';
import './Link.scss';

interface CategoryDropdownInterface extends LinkCreatorPropsInterface {
    chevron: boolean;
}

const BaseLink = ({ content, url, name }: LinkCreatorPropsInterface) => {
    return (
        <a
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.OrganizerSubNav,
                component: Components.Link,
                name: name ? name : '',
                action: Actions.Click,
            })}
            tabIndex={1}
            href={url}
            className="link-container__content"
        >
            <span>{content}</span>
        </a>
    );
};

const CategoryDropdown = ({
    content,
    navItems,
    chevron,
    name,
}: CategoryDropdownInterface) => {
    return (
        <>
            <span
                data-heap-id={getHeapDataAttribute({
                    team: Teams.SEO,
                    feature: Features.OrganizerSubNav,
                    component: Components.Dropdown,
                    name: name ? name : '',
                    action: Actions.Click,
                })}
                className="link-container__content"
            >
                {content}
                <div className="link-container__content__icon">
                    <Icon
                        className="link-container__icon-holder__icon"
                        size="small"
                        color="white"
                        type={
                            chevron ? (
                                <ChevronDownChunky />
                            ) : (
                                <ChevronUpChunky />
                            )
                        }
                    />
                </div>
            </span>
            {navItems && navItems.length > 0 && (
                <Dropdown dropdownItems={navItems} dropdownTheme="secondary" />
            )}
        </>
    );
};

const Link = ({ content, navItems, url, name }: LinkCreatorPropsInterface) => {
    const [chevron, setChevron] = useState(true);

    const linkRef = useRef<HTMLLIElement>(null);

    const handleBlur = () => {
        setChevron(true);
    };

    const handleClick = () => {
        if (!chevron) {
            linkRef.current?.blur();
        }

        setChevron(!chevron);
    };

    return (
        <li
            className="link-container"
            tabIndex={0}
            onClick={handleClick}
            onBlur={handleBlur}
            ref={linkRef}
            id={name}
        >
            {url ? (
                <BaseLink content={content} url={url} name={name} />
            ) : (
                <CategoryDropdown
                    navItems={navItems}
                    content={content}
                    chevron={chevron}
                    name={name}
                />
            )}
        </li>
    );
};

export default Link;
