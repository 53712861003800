import * as React from 'react';

export const CheckChunkierSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2084 7.697L18.0848 5.58838L9.49878 14.1137L5.91291 10.5532L3.78906 12.6605L9.49875 18.331L20.2084 7.697Z"
            fill="#00912B"
        />
    </svg>
);

CheckChunkierSvg.displayName = 'CheckChunkierSvg';
