import { ThemeTokens, VARIANTS } from '../../types';

export const BRAND_PURPLE = '#1e0a3c';
export const BRAND_PURPLE_SECONDARY = '#13002D';
export const ACCESSIBLE_BRAND_ORANGE = '#D1410C';
export const PRIMARY_BRAND_ORANGE = '#F05537';
export const GRAY_UI_2000 = '#EEEDF2';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const TRANSPARENT = 'transparent';
export const CORE_GREY_LIGHT = '#F8F7FA';
export const GRAY_LIGHT_UI_800 = '#39364F';
export const ORANGE_MARMLADE_500 = '#F05537';
export const GREY_CONTENT = '#585163';
export const GREY_PRIMARY = '#261B36';
export const GREY_SECONDARY = '#3A3247';
export const GREY_BORDER_INTERACTIVE = '#918D99';
export const GREY_BORDER_PRIMARY = '#EFEDF2';
export const GREY_BORDER_SECONDARY = '#DDDAE3';

export const defaultStyles: Record<ThemeTokens, any> = {
    background: {
        background: BRAND_PURPLE,
    },
    'overlay-gradient': {
        color: BLACK,
        background: BRAND_PURPLE,
    },
    ctaLink: {
        border: PRIMARY_BRAND_ORANGE,
        color: WHITE,
    },
    'card-ctaLink': {
        border: PRIMARY_BRAND_ORANGE,
        color: BRAND_PURPLE,
    },
    'card-text': {
        color: BRAND_PURPLE,
        underline: PRIMARY_BRAND_ORANGE,
    },
    text: {
        color: WHITE,
        underline: ACCESSIBLE_BRAND_ORANGE,
    },
    card: {
        background: WHITE,
    },
    'button-primary': {
        background: PRIMARY_BRAND_ORANGE,
        color: BRAND_PURPLE,
        fill: BRAND_PURPLE,
        border: '2px solid transparent',
        hover: {
            background: PRIMARY_BRAND_ORANGE,
        },
        focus: {
            background: PRIMARY_BRAND_ORANGE,
        },
        active: {
            background: '#bd3a20',
        },
    },
    'button-secondary': {
        background: TRANSPARENT,
        color: WHITE,
        fill: WHITE,
        border: '2px solid #FFFFFF',
        hover: {
            background: TRANSPARENT,
        },
        focus: {
            background: TRANSPARENT,
        },
        active: {
            background: TRANSPARENT,
        },
    },
    'expansion-panel-content': {
        background: GRAY_LIGHT_UI_800,
        color: WHITE,
    },
    'expansion-panel-heading': {
        color: WHITE,
    },
    'expansion-panel-trigger': {
        color: ORANGE_MARMLADE_500,
    },
    divider: {
        color: GREY_BORDER_INTERACTIVE,
    },
    'text-v2': {
        color: ORANGE_MARMLADE_500,
    },
    'background-v2': {
        background: BRAND_PURPLE_SECONDARY,
    },
    'video-headline': {
        color: ORANGE_MARMLADE_500,
    },
    'video-tagline': {
        color: WHITE,
    },
    'video-description': {
        color: WHITE,
    },
    'interlinking-card': {
        background: WHITE,
        color: BLACK,
    },
    'ratings-module': {
        border: `0px solid`,
    },
};

export const styles: Record<VARIANTS, Record<ThemeTokens, any>> = {
    core_dark: defaultStyles,
    core_light: {
        background: {
            background: WHITE,
        },
        'overlay-gradient': {
            color: WHITE,
            background: WHITE,
        },
        ctaLink: {
            border: ACCESSIBLE_BRAND_ORANGE,
            color: BRAND_PURPLE,
        },
        'card-ctaLink': {
            border: ACCESSIBLE_BRAND_ORANGE,
            color: BRAND_PURPLE,
        },
        text: {
            color: BRAND_PURPLE,
            underline: ACCESSIBLE_BRAND_ORANGE,
        },
        'card-text': {
            color: BRAND_PURPLE,
            underline: ACCESSIBLE_BRAND_ORANGE,
        },
        card: {
            background: GRAY_UI_2000,
        },
        'button-primary': {
            background: ACCESSIBLE_BRAND_ORANGE,
            color: WHITE,
            fill: WHITE,
            border: '2px solid transparent',
            hover: {
                background: ACCESSIBLE_BRAND_ORANGE,
            },
            focus: {
                background: ACCESSIBLE_BRAND_ORANGE,
            },
            active: {
                background: ACCESSIBLE_BRAND_ORANGE,
            },
        },
        'button-secondary': {
            background: TRANSPARENT,
            color: BRAND_PURPLE,
            fill: BRAND_PURPLE,
            border: `2px solid ${BRAND_PURPLE}`,
            hover: {
                background: TRANSPARENT,
            },
            focus: {
                background: TRANSPARENT,
            },
            active: {
                background: TRANSPARENT,
            },
        },
        'expansion-panel-content': {
            background: CORE_GREY_LIGHT,
            color: GREY_CONTENT,
        },
        'expansion-panel-heading': {
            color: GREY_SECONDARY,
        },
        'expansion-panel-trigger': {
            color: BRAND_PURPLE,
        },
        divider: {
            color: GREY_BORDER_PRIMARY,
        },
        'text-v2': {
            color: BRAND_PURPLE,
        },
        'background-v2': {
            background: WHITE,
        },
        'video-headline': {
            color: ORANGE_MARMLADE_500,
        },
        'video-tagline': {
            color: BLACK,
        },
        'video-description': {
            color: BRAND_PURPLE,
        },
        'interlinking-card': {
            background: WHITE,
            color: BLACK,
        },
        'ratings-module': {
            border: `0px solid`,
        },
    },
    core_gray: {
        background: {
            background: CORE_GREY_LIGHT,
        },
        'overlay-gradient': {
            color: WHITE,
            background: CORE_GREY_LIGHT,
        },
        ctaLink: {
            border: ACCESSIBLE_BRAND_ORANGE,
            color: BRAND_PURPLE,
        },
        'card-ctaLink': {
            border: ACCESSIBLE_BRAND_ORANGE,
            color: BRAND_PURPLE,
        },
        text: {
            color: BRAND_PURPLE,
            underline: ACCESSIBLE_BRAND_ORANGE,
        },
        'card-text': {
            color: BRAND_PURPLE,
            underline: ACCESSIBLE_BRAND_ORANGE,
        },
        card: {
            background: WHITE,
        },
        'button-primary': {
            background: ACCESSIBLE_BRAND_ORANGE,
            color: WHITE,
            fill: WHITE,
            border: '2px solid transparent',
            hover: {
                background: ACCESSIBLE_BRAND_ORANGE,
            },
            focus: {
                background: ACCESSIBLE_BRAND_ORANGE,
            },
            active: {
                background: ACCESSIBLE_BRAND_ORANGE,
            },
        },
        'button-secondary': {
            background: TRANSPARENT,
            color: BRAND_PURPLE,
            fill: BRAND_PURPLE,
            border: `2px solid ${BRAND_PURPLE}`,
            hover: {
                background: '#transparent',
            },
            focus: {
                background: '#transparent',
            },
            active: {
                background: '#transparent',
            },
        },
        'expansion-panel-content': {
            background: GRAY_UI_2000,
            color: GREY_CONTENT,
        },
        'expansion-panel-heading': {
            color: GREY_SECONDARY,
        },
        'expansion-panel-trigger': {
            color: GREY_SECONDARY,
        },
        divider: {
            color: GREY_BORDER_SECONDARY,
        },
        'text-v2': {
            color: BRAND_PURPLE,
        },
        'background-v2': {
            background: CORE_GREY_LIGHT,
        },
        'video-headline': {
            color: BRAND_PURPLE,
        },
        'video-tagline': {
            color: ORANGE_MARMLADE_500,
        },
        'video-description': {
            color: BRAND_PURPLE,
        },
        'interlinking-card': {
            background: WHITE,
            color: BLACK,
        },
        'ratings-module': {
            border: `0px solid`,
        },
    },
    mood_01: defaultStyles,
    mood_02: defaultStyles,
    mood_03: defaultStyles,
    warm_dark: defaultStyles,
    warm_white: defaultStyles,
    pure_white: defaultStyles,
    glow_brite: defaultStyles,
};
