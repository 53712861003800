import React, { useEffect, useState } from 'react';

import { getSigninOrSignupUrl } from '@eventbrite/global-nav';
import { gettext } from '@eventbrite/i18n';
import './StickyBar.scss';
import { StickyBarPropsInterface } from './utils/interfaces';

export const StickyBar = ({ env, className }: StickyBarPropsInterface) => {
    const [reachedBottom, setReachedBottom] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const documentHeight = document.body.scrollHeight;
            const currentScroll = window.scrollY + window.innerHeight;
            const modifier = 10;
            const hasReachedBottom = currentScroll + modifier > documentHeight;

            setReachedBottom(hasReachedBottom);
        };
        window.addEventListener('scroll', handleScroll);
    }, []);
    const stickyBarClasses = reachedBottom
        ? 'hide-mlp-cta-sticky-bar'
        : 'mlp-cta-sticky-bar';

    return (
        <nav
            className={`${stickyBarClasses} ${className}`}
            data-testid="mlp-cta-sticky-bar"
        >
            <a
                className="mlp-cta-sticky-bar__contact-sales"
                href={`${env.serverUrl}/l/contact-eventbrite-sales/`}
            >
                {gettext('Contact Sales')}
            </a>
            <a
                className="mlp-cta-sticky-bar__get-started"
                href={getSigninOrSignupUrl(env.signupUrl)}
            >
                {gettext('get started')}
            </a>
        </nav>
    );
};

export default StickyBar;
