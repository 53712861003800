import { ThemeTokens, VARIANTS } from '../../types';
import { styles as coreStyles, TRANSPARENT, WHITE } from './core-theme';

const styles: Record<VARIANTS, Record<ThemeTokens, any>> = {
    ...coreStyles,
};

styles[VARIANTS.WARM_DARK] = {
    background: {
        background: 'var(--r-brand-warm-gray-dark, #211D1A)',
    },
    text: {
        color: WHITE,
        underline: 'var(--r-brand-brite-orange, #FF5010)',
        'font-family': 'var(--r-primary-font)',
    },
    ctaLink: {
        border: 'var(--r-brand-brite-orange, #FF5010)',
        color: WHITE,
    },
    // Hero CTA Module
    'overlay-gradient': {
        color: 'var(--r-brand-warm-gray-dark, #211D1A)',
        background: 'var(--r-brand-warm-gray-dark, #211D1A)',
    },
    // Resource Card Module
    card: {
        background: WHITE,
    },
    'card-ctaLink': {
        border: 'var(--r-brand-brite-orange, #FF5010)',
        color: 'var(--r-core-fg-base, #161719)',
    },
    'card-text': {
        color: 'var(--r-core-fg-base, #161719)',
        underline: 'var(--r-brand-brite-orange, #FF5010)',
    },
    // Buttons
    'button-primary': {
        background: 'var(--r-brand-brite-orange, #FF5010) !important',
        color: WHITE,
        fill: WHITE,
        border: '2px solid transparent !important',
        hover: {
            background: 'var(--r-brand-brite-orange-hover, #FF7E65) !important',
        },
        focus: {
            background: '#FF9C8C',
        },
        active: {
            background: '#FF9C8C',
        },
    },
    'button-secondary': {
        background: TRANSPARENT,
        color: WHITE,
        fill: WHITE,
        border: `2px solid ${WHITE}`,
        hover: {
            background: '#7A7C811A',
        },
        focus: {
            background: '#7A7C8133',
        },
        active: {
            background: '#7A7C8133',
        },
    },
    // FAQ Module styles
    'background-v2': {
        background: 'var(--r-brand-warm-gray-dark, #211D1A)',
    },
    'text-v2': {
        color: WHITE,
    },
    'expansion-panel-heading': {
        color: 'var(--r-brand-warm-gray-white, #F5F5F0)',
    },
    'expansion-panel-trigger': {
        color: WHITE,
    },
    'expansion-panel-content': {
        background: WHITE,
        color: 'var(--r-core-fg-secondary, #3D3F43)',
    },
    divider: {
        color: '#BFBFC1',
    },
    // Video Module Styles
    'video-headline': {
        color: 'var(--r-brand-warm-gray-white, #F5F5F0)',
    },
    'video-tagline': {
        color: WHITE,
    },
    'video-description': {
        color: WHITE,
    },
    'interlinking-card': {
        background: 'var(--r-core-fg-secondary, #3D3F43)',
        color: WHITE,
    },
    // Ratings module
    'ratings-module': {
        border: '0px',
    },
};

styles[VARIANTS.WARM_WHITE] = {
    background: {
        background: 'var(--r-brand-warm-gray-white, #F5F5F0)',
    },
    text: {
        color: 'var(--r-core-fg-base, #161719)',
        underline: 'var(--r-brand-brite-orange, #FF5010)',
        'font-family': 'var(--r-primary-font)',
    },
    ctaLink: {
        border: 'var(--r-brand-brite-orange, #FF5010)',
        color: 'var(--r-core-fg-base, #161719)',
    },
    // Hero CTA Module
    'overlay-gradient': {
        color: 'var(--r-brand-warm-gray-white, #F5F5F0)',
        background: 'var(--r-brand-warm-gray-white, #F5F5F0)',
    },
    // Resource Card Module
    card: {
        background: WHITE,
    },
    'card-ctaLink': {
        border: 'var(--r-brand-brite-orange, #FF5010)',
        color: 'var(--r-core-fg-base, #161719)',
    },
    'card-text': {
        color: 'var(--r-core-fg-base, #161719)',
        underline: 'var(--r-brand-brite-orange, #FF5010)',
    },
    // Buttons
    'button-primary': {
        background: 'var(--r-core-fg-base, #161719) !important',
        color: WHITE,
        fill: WHITE,
        border: '2px solid transparent !important',
        hover: {
            background: 'var(--r-core-action-hover, #25272B) !important',
        },
        focus: {
            background: '#313337',
        },
        active: {
            background: '#313337',
        },
    },
    'button-secondary': {
        background: TRANSPARENT,
        color: 'var(--r-core-fg-base, #161719)',
        fill: 'var(--r-core-fg-base, #161719)',
        border: `2px solid var(--r-core-fg-base, #161719)`,
        hover: {
            background: '#7A7C811A',
        },
        focus: {
            background: '#7A7C8133',
        },
        active: {
            background: '#7A7C8133',
        },
    },
    // FAQ Module styles
    'background-v2': {
        background: 'var(--r-brand-warm-gray-white, #F5F5F0)',
    },
    'text-v2': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'expansion-panel-heading': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'expansion-panel-trigger': {
        color: '--r-core-fg-base',
    },
    'expansion-panel-content': {
        background: WHITE,
        color: 'var(--r-core-fg-secondary, #3D3F43)',
    },
    divider: {
        color: '#BFBFC1',
    },
    // Video Module Styles
    'video-headline': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'video-tagline': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'video-description': {
        color: 'var(--r-core-fg-secondary, #3D3F43)',
    },
    'interlinking-card': {
        background: 'var(--r-brand-glow-brite, #E9F7C3)',
        color: 'var(--r-core-fg-base, #161719)',
    },
    // Ratings module
    'ratings-module': {
        border: '0px',
    },
};

styles[VARIANTS.PURE_WHITE] = {
    background: {
        background: WHITE,
    },
    text: {
        color: 'var(--r-core-fg-base, #161719)',
        underline: 'var(--r-brand-brite-orange, #FF5010)',
        'font-family': 'var(--r-primary-font)',
    },
    ctaLink: {
        border: 'var(--r-brand-brite-orange, #FF5010)',
        color: 'var(--r-core-fg-base, #161719)',
    },
    // Hero CTA Module
    'overlay-gradient': {
        color: WHITE,
        background: WHITE,
    },
    // Resource Card Module
    card: {
        background: 'var(--r-brand-warm-gray-white, #F5F5F0)',
    },
    'card-ctaLink': {
        border: 'var(--r-core-fg-base, #161719)',
        color: 'var(--r-core-fg-base, #161719)',
    },
    'card-text': {
        color: 'var(--r-core-fg-base, #161719)',
        underline: 'var(--r-brand-brite-orange, #FF5010)',
    },
    // Buttons
    'button-primary': {
        background: 'var(--r-brand-brite-orange, #FF5010) !important',
        color: WHITE,
        fill: WHITE,
        border: '2px solid transparent !important',
        hover: {
            background: 'var(--r-brand-brite-orange-hover, #FF7E65) !important',
        },
        focus: {
            background: '#FF9C8C',
        },
        active: {
            background: '#FF9C8C',
        },
    },
    'button-secondary': {
        background: TRANSPARENT,
        color: 'var(--r-core-fg-base, #161719)',
        fill: 'var(--r-core-fg-base, #161719)',
        border: `2px solid ${'var(--r-core-fg-base, #161719)'}`,
        hover: {
            background: '#7A7C811A',
        },
        focus: {
            background: '#7A7C8133',
        },
        active: {
            background: '#7A7C8133',
        },
    },
    // FAQ Module styles
    'background-v2': {
        background: WHITE,
    },
    'text-v2': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'expansion-panel-heading': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'expansion-panel-trigger': {
        color: '--r-core-fg-base',
    },
    'expansion-panel-content': {
        background: 'var(--r-brand-warm-gray-white, #F5F5F0)',
        color: 'var(--r-core-fg-secondary, #3D3F43)',
    },
    divider: {
        color: '#BFBFC1',
    },
    // Video Module Styles
    'video-headline': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'video-tagline': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'video-description': {
        color: 'var(--r-core-fg-secondary, #3D3F43)',
    },
    'interlinking-card': {
        background: 'var(--r-brand-warm-gray-white, #F5F5F0)',
        color: 'var(--r-core-fg-base, #161719)',
    },
    // Ratings module
    'ratings-module': {
        border: `1px solid ${'var(--brand-warm-gray-dark, #211D1A)'}`,
    },
};

styles[VARIANTS.GLOW_BRITE] = {
    background: {
        background: 'var(--r-brand-glow-brite, #E9F7C3)',
    },
    text: {
        color: 'var(--r-core-fg-base, #161719)',
        underline: 'var(--r-core-fg-base, #161719)',
        'font-family': 'var(--r-primary-font)',
    },
    ctaLink: {
        border: 'var(--r-brand-brite-orange, #FF5010)',
        color: 'var(--r-core-fg-base, #161719)',
    },
    // Hero CTA Module
    'overlay-gradient': {
        color: 'var(--r-brand-glow-brite, #E9F7C3)',
        background: 'var(--r-brand-glow-brite, #E9F7C3)',
    },
    // Resource Card Module
    card: {
        background: WHITE,
    },
    'card-ctaLink': {
        border: 'var(--r-brand-brite-orange, #FF5010)',
        color: 'var(--r-core-fg-base, #161719)',
    },
    'card-text': {
        color: 'var(--r-core-fg-base, #161719)',
        underline: 'var(--r-brand-brite-orange, #FF5010)',
    },
    // Buttons
    'button-primary': {
        background: 'var(--r-core-fg-base, #161719) !important',
        color: WHITE,
        fill: WHITE,
        border: '2px solid transparent !important',
        hover: {
            background: 'var(--r-core-action-hover, #25272B) !important',
        },
        focus: {
            background: '#313337',
        },
        active: {
            background: '#313337',
        },
    },
    'button-secondary': {
        background: TRANSPARENT,
        color: 'var(--r-core-fg-base, #161719)',
        fill: 'var(--r-core-fg-base, #161719)',
        border: `2px solid var(--r-core-fg-base, #161719)`,
        hover: {
            background: '#7A7C811A',
        },
        focus: {
            background: '#7A7C8133',
        },
        active: {
            background: '#7A7C8133',
        },
    },
    // FAQ Module styles
    'background-v2': {
        background: 'var(--r-brand-glow-brite, #E9F7C3)',
    },
    'text-v2': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'expansion-panel-heading': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'expansion-panel-trigger': {
        color: '--r-core-fg-base',
    },
    'expansion-panel-content': {
        background: WHITE,
        color: 'var(--r-core-fg-secondary, #3D3F43)',
    },
    divider: {
        color: '#BFBFC1',
    },
    // Video Module Styles
    'video-headline': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'video-tagline': {
        color: 'var(--r-core-fg-base, #161719)',
    },
    'video-description': {
        color: 'var(--r-core-fg-secondary, #3D3F43)',
    },
    'interlinking-card': {
        background: 'var(--r-brand-warm-gray-white, #F5F5F0)',
        color: 'var(--r-core-fg-base, #161719)',
    },
    // Ratings module
    'ratings-module': {
        border: `1px solid ${'var(--brand-warm-gray-dark, #211D1A)'}`,
    },
};

export const getThemeTokens = (token: ThemeTokens, variant: VARIANTS) => {
    return styles[variant][token];
};
