import logger from '@eventbrite/client-logger';
import {
    BarGraph,
    Bolt,
    Calendar,
    Check,
    CreditCardBack,
    Friends,
    Gear,
    Globe,
    Headphone,
    Lock,
    MagnifyingGlass,
    Megaphone,
    ScreenSmall,
    Ticket,
} from '@eventbrite/eds-iconography';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { VARIANTS } from '../../utils/types';
import CtaButton from '../sharedComponents/CtaButton';
import DisclaimerText from '../sharedComponents/DisclaimerText';
import SignupForm from '../sharedComponents/SignupForm';

export const SIGNUP_URL = '/signin/signup/?referrer=%2Fcreate';

export const formatEmailRedirectPath = (urlPath: string, email: string) => {
    const params = new URLSearchParams(urlPath);
    // Check if url path is split into query params or remains the same
    const queryExistsInPath = !params.has(urlPath);

    if (queryExistsInPath) {
        urlPath += `&email=${email}`;
    } else {
        urlPath += `/?email=${email}`;
    }

    return decodeURIComponent(urlPath);
};

export const convertContentfulVariantToEnum = (
    variant: string | undefined,
): VARIANTS | undefined => {
    if (variant) {
        const normalizedInput = variant.trim().toUpperCase().replace(' ', '_');

        switch (normalizedInput) {
            case 'CORE_DARK':
                return VARIANTS.CORE_DARK;
            case 'CORE_LIGHT':
                return VARIANTS.CORE_LIGHT;
            case 'CORE_GRAY':
                return VARIANTS.CORE_GRAY;
            case 'MOOD_01':
                return VARIANTS.MOOD_01;
            case 'MOOD_02':
                return VARIANTS.MOOD_02;
            case 'MOOD_03':
                return VARIANTS.MOOD_03;
            case 'WARM_DARK':
                return VARIANTS.WARM_DARK;
            case 'WARM_WHITE':
                return VARIANTS.WARM_WHITE;
            case 'PURE_WHITE':
                return VARIANTS.PURE_WHITE;
            case 'GLOW_BRITE':
                return VARIANTS.GLOW_BRITE;
            default:
                return undefined;
        }
    }
    return undefined;
};

export const sanitizeHTML = (inputHTML: string): string => {
    //allowing all tags and attributes
    return sanitizeHtml(inputHTML, {
        allowedTags: false,
        allowedAttributes: false,
    });
};

export const relativeZindexStyle: React.CSSProperties = {
    position: 'relative',
    zIndex: 100,
};

interface IconTypeDictionary {
    'bar-graph': React.ReactNode;
    bolt: React.ReactNode;
    calendar: React.ReactNode;
    check: React.ReactNode;
    'credit-card-back': React.ReactNode;
    friends: React.ReactNode;
    gear: React.ReactNode;
    globe: React.ReactNode;
    headphone: React.ReactNode;
    lock: React.ReactNode;
    'magnifying-glass': React.ReactNode;
    megaphone: React.ReactNode;
    'screen-small': React.ReactNode;
    ticket: React.ReactNode;
}

const ICON_TYPE_MAPPING: IconTypeDictionary = {
    'bar-graph': <BarGraph />,
    bolt: <Bolt />,
    calendar: <Calendar />,
    check: <Check />,
    'credit-card-back': <CreditCardBack />,
    friends: <Friends />,
    gear: <Gear />,
    globe: <Globe />,
    headphone: <Headphone />,
    lock: <Lock />,
    'magnifying-glass': <MagnifyingGlass />,
    megaphone: <Megaphone />,
    'screen-small': <ScreenSmall />,
    ticket: <Ticket />,
};

export const getIconType = (iconType: string) => {
    if (!Object.prototype.hasOwnProperty.call(ICON_TYPE_MAPPING, iconType)) {
        logger.warn('Unrecognized icon type passed in', { iconType });
        return ICON_TYPE_MAPPING['magnifying-glass'];
    }
    return ICON_TYPE_MAPPING[iconType as keyof IconTypeDictionary];
};

interface CtaSigninSectionProps {
    shouldIncludeSigninFields: boolean;
    signupGridClasses: string;
    inputFieldClasses?: string;
    showMktgFormClass: boolean;
    buttonText: string;
    buttonUrl?: string;
}
export class CtaSigninSection extends React.PureComponent<CtaSigninSectionProps> {
    render() {
        const {
            shouldIncludeSigninFields,
            signupGridClasses,
            inputFieldClasses,
            showMktgFormClass,
            buttonText,
            buttonUrl,
        } = this.props;
        return (
            <React.Fragment>
                {shouldIncludeSigninFields && (
                    <React.Fragment>
                        <SignupForm
                            buttonText={buttonText}
                            signupGridClasses={signupGridClasses}
                            inputFieldClasses={inputFieldClasses}
                            showMktgFormClass={showMktgFormClass}
                            buttonUrl={buttonUrl || SIGNUP_URL}
                        />
                        <DisclaimerText buttonText={buttonText} />
                    </React.Fragment>
                )}
                {!shouldIncludeSigninFields && (
                    <CtaButton
                        buttonText={buttonText}
                        buttonClasses={'eds-align--center'}
                        buttonUrl={buttonUrl || SIGNUP_URL}
                    />
                )}
            </React.Fragment>
        );
    }
}
